import React, { useEffect, useState, Fragment } from 'react';
import { Box, Table, TableBody, Snackbar, Alert, TableCell, TableContainer, TableHead, TableRow, Paper, Fab, Modal, styled, Typography, Divider, TextField, Button, Autocomplete } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 as uuidv4 } from 'uuid';
import { red, blue } from '@mui/material/colors';

// Estilos personalizados para las celdas de la cabecera
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#e0e0e0', // Escala de grises
    color: theme.palette.common.black,
    fontWeight: 'bold',
}));

// Contenedor de tabla con desplazamiento y responsividad
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    // maxHeight: '70vh', // Altura máxima con scroll
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
        overflowX: 'auto', // Scroll horizontal en pantallas pequeñas
    },
}));

// Estilos del modal
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '80%', md: 600 }, // Ancho adaptable
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 3,
    maxHeight: '80vh', // Evita que el modal desborde
    overflowY: 'auto',
};

const Menu = () => {
    const [categorias, setCategorias] = useState([]);
    const [productos, setProductos] = useState([]);
    const [open, setOpen] = useState(false);
    //PLANTEL
    const [categoriasArray, setCategoriasArray] = useState([]);
    const [valueCategorias, setValueCategorias] = useState(null);
    const [inputValueCategorias, setInputValueCategorias] = useState('');
    const [newProducto, setNewProducto] = useState({
        nombre: '',
        precio: '',
        categoria: '',
        idProducto: '',
    });

    const [contenidoSnackBar, setContenidoSnackBar] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewProducto((prev) => ({
            ...prev,
            [name]: value,
            idProducto: uuidv4()
        }));
    };

    const cargarMenu = () => {
        axios.get('https://mariscosdelvalle.com.mx/apis/menu.php', {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
            .then((response) => {
                setCategorias(response.data.categorias);
                setProductos(response.data.productos);
            })
            .catch((error) => console.error(error));
    }

    const cargarCategorias = () => {
        axios.get('https://mariscosdelvalle.com.mx/apis/categorias.php', {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
            .then((response) => {
                setCategoriasArray(response.data);
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        cargarMenu();
        cargarCategorias();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        console.log(newProducto);
        if (newProducto.nombre === '' || newProducto.precio === '' || newProducto.categoria === '') {
            console.log('Datos vacios');
            setContenidoSnackBar('Datos Vacios');
            setSnackbarOpen(true);
        } else {
            axios.post('https://mariscosdelvalle.com.mx/apis/insertarProducto.php', newProducto, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => {
                    console.log(response.data);
                    setInputValueCategorias('');
                    setValueCategorias(null);
                    setNewProducto({
                        nombre: '',
                        precio: '',
                        categoria: '',
                        idProducto: ''
                    })
                    cargarMenu();
                    setOpen(false);
                })
                .catch((error) => console.error(error));
        };
    }
    const editarProducto = (e) => {
        console.log(e);
    }

    const eliminarProducto = (e) => {
        const idPro = e.id_producto;
        console.log(idPro)
    }
    return (
        <Fragment>
            {categorias.length > 0 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
                    <StyledTableContainer component={Paper}>
                        {categorias.map((arrayCate, indexCate) => (
                            <>
                                <h4 style={{ textAlign: 'center', background: '#93E1D8', margin: '0px' }}>{arrayCate.categoria}</h4>
                                <Table key={indexCate} sx={{ marginBottom: 2 }} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Producto</StyledTableCell>
                                            <StyledTableCell align="center">Precio</StyledTableCell>
                                            <StyledTableCell align="right"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productos.map(
                                            (arrayProd, indexProd) =>
                                                arrayCate.categoria === arrayProd.categoria && (
                                                    <TableRow key={indexProd}>
                                                        <TableCell sx={{ width: '25%' }}>{arrayProd.producto}</TableCell>
                                                        <TableCell align="center" sx={{ width: '25%' }}>
                                                            {arrayProd.precio}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ width: '25%' }}>
                                                            <EditNoteIcon onClick={() => { editarProducto(arrayProd) }} sx={{ color: blue[700] }} />
                                                            <DeleteForeverIcon onClick={() => { eliminarProducto(arrayProd) }} sx={{ color: red[500] }} />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        ))}
                    </StyledTableContainer>

                    {/* Botón flotante centrado debajo de la tabla */}
                    <Fab
                        sx={{
                            backgroundColor: 'rgba(0, 128, 0, 0.2)',
                            mt: 3,
                            alignSelf: 'center',
                            position: 'sticky',
                            bottom: 16,
                        }}
                        onClick={handleOpen}
                    >
                        <FontAwesomeIcon icon={faPlus} size="2x" />
                    </Fab>
                </Box>
            ) : null}

            {/* Modal */}
            <Modal open={open} onClose={() => { setOpen(false) }}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Añadir nuevo producto
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <TextField fullWidth label="Nombre del producto" name='nombre' margin="normal" value={newProducto.nombre} onChange={handleChange} />
                    <TextField fullWidth label="Precio" margin="normal" name='precio' value={newProducto.precio} onChange={handleChange} />
                    <Autocomplete
                        freeSolo
                        value={valueCategorias}
                        name='categoria'
                        onChange={(_, v) => setNewProducto((prev) => ({
                            ...prev,
                            categoria: v.value,
                        }))}
                        inputValue={inputValueCategorias}
                        onInputChange={(_, v) => { setInputValueCategorias(v); }}
                        options={categoriasArray}
                        renderInput={(params) => <TextField {...params} label="Categoria" />}
                    />
                    <Button variant="contained" fullWidth onClick={handleClose} sx={{ mt: 2 }}>
                        Guardar
                    </Button>
                </Box>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                    {contenidoSnackBar}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default Menu;
