import React, { useState, useEffect, useRef } from 'react';
import { Typography, IconButton, } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Card, CardContent, List, ListItem, ListItemText, Snackbar, Alert } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaidIcon from '@mui/icons-material/Paid';
import axios from 'axios';
import {
    Box, Modal, Divider, TextField,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CardMesa = ({ value, noOrden, funcion }) => {
    const [optMenu, setOptMenu] = useState(false);

    const [pago, setPago] = useState([]);
    const [pagoTotal, setPagoTotal] = useState(0);
    const [openPagar, setOpenPagar] = useState(false);
    const [metodoDePago, setMetodoDePago] = useState('');
    const [contenidoSnackBar, setContenidoSnackBar] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [cambio, setCambio] = useState('');
    const [propina, setPropina] = useState('');
    const menuRef = useRef(null);
    const rol = sessionStorage.getItem('rol');

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const toggleMenu = () => {
        setOptMenu((prev) => !prev);
    };
    const [contenidoEfectivo, setContenidoEfectivo] = useState(false);

    const handleChangeCambio = (event) => {
        setCambio(event.target.value); // Captura y actualiza el valor en el estado
    };

    const handleChangePago = (event) => {
        //efectivo / otro
        if (event.target.value === 'efectivo') setContenidoEfectivo(true);
        if (event.target.value === 'otro') setContenidoEfectivo(false);
        setMetodoDePago(event.target.value);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', md: 'auto' },
        maxWidth: 800, // Ajuste del ancho

        bgcolor: 'background.paper',
        borderRadius: 4,
        boxShadow: 24,
        p: 2,
        maxHeight: '80vh',
        overflowY: 'auto',
    };

    const handleClosePagar = () => setOpenPagar(false);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setOptMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handlePagar = (event, e) => {
        setContenidoEfectivo(false);
        setMetodoDePago('');
        setPagoTotal(0);
        setCambio('');
        setPropina('');
        setPago([]);
        let totalPago = 0;
        for (let i = 0; i < e.length; i++) {
            totalPago = totalPago + (e[i].cantidad * e[i].precio);
        }
        setPagoTotal(totalPago);
        setPago(e);
        setOpenPagar(true);
    }

    const functionPagar = (event) => {
        console.log(noOrden);

        if (metodoDePago === '') {
            setContenidoSnackBar('Favor de seleccionar un metodo de pago');
            setSnackbarOpen(true);
            return;
        } else {
            const obj = {
                id_mesa: event[0].id_mesa,
                total: pagoTotal,
                metodo: metodoDePago,
                efectivo: cambio,
                propinas: propina,
                orden: noOrden,
                cambio: (cambio - pagoTotal) - propina
            }

            const pedidoJSON = JSON.stringify(obj);
            axios.post('https://mariscosdelvalle.com.mx/apis/cerrarCuenta.php', pedidoJSON, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    setOpenPagar(false);
                })
                .catch((error) => {
                    console.error(error);
                });

        }
        setPago([]);
        setPagoTotal(0);
    }


    return (
        <>
            <Grid
                item
                xs={12} sm={6} md={6} lg={6} // 4 columnas en pantallas grandes, 2 en medianas, 1 en pequeñas 
                key={noOrden}
                sx={{ width: '100%' }}

            >
                <Card style={{ position: 'relative', background: '#F3F0F7' }}  >
                    {/* Botón de tres puntos */}
                    <IconButton
                        style={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={toggleMenu}
                    >
                        <MoreVertIcon />
                    </IconButton>


                    <div style={{ position: 'relative' }} on>
                        {optMenu && (
                            <div
                                ref={menuRef}
                                style={{
                                    position: 'absolute',
                                    top: '40px',
                                    right: '0',
                                    border: '1px solid #ccc',
                                    background: '#fff',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                    zIndex: 1,

                                }}>
                                <div onClick={() => funcion('Editar', value)} style={{ padding: '10px', cursor: 'pointer' }}>
                                    Editar
                                </div>
                                {rol === 'Admin' || rol === 'Full' ?
                                    <div onClick={() => { funcion('Eliminar', value[0].id_mesa); setOptMenu(false); }} style={{ padding: '10px', cursor: 'pointer' }}>
                                        Eliminar
                                    </div>
                                    : null}

                            </div>
                        )}
                    </div>

                    <CardContent sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom>
                            Mesa: {value[0].mesa}
                        </Typography>

                        {/* Grid para hacer columnas cuando haya más de 7 productos */}
                        <Grid container sx={{ flexWrap: 'wrap' }}>
                            {Object.entries(value).map(([index, array], i) => (
                                <Grid
                                    item
                                    xs={12} // 12 para que cada producto ocupe toda la fila en xs
                                    sm={6} // 6 para hacer dos columnas en sm (pantallas medianas)
                                    md={6} // 4 para tres columnas en pantallas más grandes
                                    lg={6}
                                    key={index}
                                >
                                    <List sx={{ padding: 0 }}>
                                        <ListItem sx={{ padding: '4px 0' }}>
                                            <ListItemText primary={`${array.cantidad} - ${array.producto}`} />
                                        </ListItem>
                                    </List>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                    {rol === 'Admin' || rol === 'Full' ?
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '2px' }}>
                            <PaidIcon color='success' sx={{ fontSize: 40 }} onClick={() => { handlePagar('Pagar', value); setOptMenu(false); }} />
                        </div>
                        : null}
                </Card >
            </Grid >
            {/* Modal de pagar */}
            <Modal open={openPagar} onClose={handleClosePagar}>
                <Box sx={{
                    ...modalStyle,
                    maxWidth: { xs: '90%', sm: '600px' },
                    width: '100%',
                    margin: 'auto',
                    padding: { xs: 2, sm: 3 },
                    display: 'flex',
                    flexDirection: 'column', // Para apilar los Box uno debajo del otro
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {/* Box del Ticket */}
                    <Box sx={{
                        width: { xs: '100%', md: '300px' },
                        bgcolor: '#f0f0f0',
                        borderRadius: 2,
                        boxShadow: 3,
                        p: { xs: 2, sm: 3 },
                        flexShrink: 0,
                    }}>
                        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                            Ticket
                        </Typography>
                        <Divider sx={{ mb: 2 }} />

                        {/* Detalle de productos */}
                        {pago.map((arrayPago, index) => (
                            <Box key={index} sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 1,
                                fontSize: { xs: '0.9rem', sm: '1rem' }
                            }}>
                                <Typography>{arrayPago.cantidad} x {arrayPago.producto}</Typography>
                                <Typography>${(arrayPago.cantidad * arrayPago.precio).toFixed(2)}</Typography>
                            </Box>
                        ))}

                        <Divider sx={{ my: 2 }} />

                        {/* Total */}
                        <Typography variant="h6" align="center" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                            Total: ${pagoTotal.toFixed(2)}
                        </Typography>
                    </Box>

                    {/* Nuevo Box debajo del Ticket */}
                    <Box sx={{
                        width: { xs: '100%', md: '300px' },
                        bgcolor: '#ffffff', // Cambia el color de fondo si es necesario
                        borderRadius: 2,
                        boxShadow: 3,
                        p: { xs: 2, sm: 3 },
                        mt: 2, // Margen superior para separar los dos Box
                        flexShrink: 0,
                    }}>

                        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Metodo de pago</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={metodoDePago}
                                        label="Metodo de pago"
                                        onChange={handleChangePago}
                                    >
                                        <MenuItem value='efectivo'>Efectivo</MenuItem>
                                        <MenuItem value='otro'>Tarjeta/Transferencia</MenuItem>
                                    </Select>
                                </FormControl>
                                {contenidoEfectivo ?
                                    <>
                                        <TextField
                                            label="Cantidad"
                                            type="number"
                                            variant="outlined"
                                            onChange={handleChangeCambio}
                                            sx={{
                                                width: '100%',
                                                mt: 2,
                                            }}
                                        />
                                        <TextField
                                            label="Propina"
                                            type="number"
                                            variant="outlined"
                                            value={propina}
                                            onChange={(e) => { setPropina(e.target.value) }}
                                            sx={{
                                                width: '100%',
                                                mt: 2,
                                            }}
                                        />
                                        <TextField
                                            label="Cambio"
                                            type="text"
                                            variant="outlined"
                                            value={
                                                (cambio - pagoTotal) < 0 ? 0 : (cambio - pagoTotal) - propina
                                            }// cambio - pagoTotal Cambia este valor según lo que desees mostrar
                                            disabled
                                            sx={{
                                                width: '100%',
                                                mt: 2,
                                            }}
                                        />
                                    </>
                                    : null}
                            </Box>
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                            <button onClick={() => { functionPagar(pago) }}>Pagar</button>
                        </Typography>
                    </Box>

                </Box>
            </Modal>
            {/* TOAST */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
                    {contenidoSnackBar}
                </Alert>
            </Snackbar>
        </>
    )
}

export default CardMesa;